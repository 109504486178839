import { createElement as rc, useEffect, useState, memo, useCallback } from 'react';
import logging from '@sstdev/lib_logging';
import { View, styled, h1, fromTheme, Button } from 'lib_ui-primitives';
import { useOrchestrator, useUserContext } from '../hooks';
import HNode from '../HNode';
import setAppName from '../utilities/setAppName';
import useEventSink from '../hooks/useEventSink';
import { constants, network } from 'lib_ui-services';

const { ASYNC_LOAD_STATUS } = constants;

//specify width on "root" element allows us to use percentages deeper down.
const Root = styled(View).attrs({ name: 'UseCaseRoot' })`
    overflow: hidden;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
`;

const LoadingStyle = styled(View)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #888888;
    flex-direction: column;
`;

const Centered = styled(h1)`
    text-align: center;
    margin-left: ${fromTheme('viewMargin')};
    margin-right: ${fromTheme('viewMargin')};
`;

const ErrorMessage = styled(Centered)`
    font-size: ${fromTheme('fontSize')};
    margin-bottom: ${fromTheme('viewMargin')};
`;

const ErrorDetails = styled(Centered)`
    font-size: 12px;
    text-align: center;
    margin-top: auto;
`;

const ClearCacheButton = styled(Button)`
    margin-top: ${fromTheme('viewMargin')};
`;

const _p = {
    useOrchestrator,
    useUserContext,
    setAppName
};

export const _private = _p;

/**
 * Any logic related to the use case lives in this file.
 * When we get here, we are GUARANTEED to have a user with a usecase selected.
 * 1. The basic application orchestration
 * 2. Mobile detection
 * 3. Initiating Theme selection
 * 4. Licence agreement acceptance
 * 5. (Kicking off) Use case rendering (if applicable)
 */
function _UseCaseRoot(props) {
    const { currentRoute } = props || {};
    const [applicationStatus, setAppStatus] = useState('Identifying Use Case.');
    const [clearCacheButtonDisabled, setClearCacheButtonDisabled] = useState(false);
    const [clearCacheButtonText, setClearCacheButtonText] = useState('Clear Cache');
    const profile = _p.useUserContext();
    const [, publish] = useEventSink();
    const { status: orchestratorStatus, error: orchestratorError, useCase } = _p.useOrchestrator();

    useEffect(() => {
        if (!profile.activeUseCase) {
            setAppStatus(`Failed to load requested use case for user ${profile.displayName || profile.nickname}`);
            return;
        }
        const useCaseTitle = profile.activeUseCase['metaui:useCase'].title;
        switch (orchestratorStatus) {
            case ASYNC_LOAD_STATUS.notStarted:
                setAppStatus('Selecting Use Case.');
                break;
            case ASYNC_LOAD_STATUS.pending: {
                const activeTenant = profile.activeTenant;
                setAppStatus(
                    `Loading ${useCaseTitle} for ${profile.displayName} on ${activeTenant['identity:tenant'].title}.`
                );
                break;
            }
            case ASYNC_LOAD_STATUS.success: {
                if (!useCase) {
                    setAppStatus(`Unable to load ${useCaseTitle}.`);
                    break;
                }
                setAppStatus(`${useCaseTitle} ready.`);

                // Inform the rest rest of the app that we have a use case UI.
                // This is for stuff like the service worker installer.
                publish(
                    { initialRenderComplete: true },
                    { verb: 'change', namespace: 'application', relation: 'runtime' }
                );
                break;
            }
            default:
                //error...
                setAppStatus('Failed to load use case.');
                break;
        }
    }, [orchestratorStatus, profile, useCase, publish]);

    useEffect(() => {
        if (useCase) {
            setAppStatus('Rendering User Interface.');
        }
        _p.setAppName(useCase);
    }, [useCase]);

    useEffect(() => logging.debug('[USECASE] ' + applicationStatus), [applicationStatus]);
    useEffect(() => {
        if (orchestratorError) {
            let error = orchestratorError;
            if (Array.isArray(orchestratorError?.form)) {
                error = orchestratorError.form[0];
            }
            if (error._isUserError) {
                logging.info('[USECASE]', error.stack || error.message || error);
            } else {
                logging.error('[USECASE]', error.stack || error.message || error);
            }
        }
    }, [orchestratorError]);

    /**
     * Handles clearing the local cache
     */
    const handleClearCache = useCallback(async () => {
        setClearCacheButtonDisabled(true);
        setClearCacheButtonText('Clearing cache...');
        try {
            await network.clearCache();
        } catch (error) {
            logging.error('Failed to clear cache:', error);
            setClearCacheButtonDisabled(false);
            setClearCacheButtonText('Clear Cache');
        }
    }, []);

    /**
     * Error message displayed to the user when an unexpected issue occurs
     */
    const errorMessage =
        "We've encountered an unexpected issue. To help resolve this, please try clearing your local cache. This might fix the problem and get you back on track.";

    // prettier-ignore
    return rc(Root, { name: 'UseCaseRoot' },
        // feedback while loading
        !useCase ? rc(LoadingStyle, {}, rc(Centered, null, applicationStatus)) : null,
        !useCase && orchestratorError ? rc(LoadingStyle, {},
            // Make the friendly error message more visible to the user
            rc(ErrorMessage, null, errorMessage),
            // eslint-disable-next-line no-undef
            !__SST_REACT_NATIVE__ && rc(ClearCacheButton, { onClick: handleClearCache, value: clearCacheButtonText, disabled: clearCacheButtonDisabled }),  // hide on native until implemented
            // Display the orchestratorError message at the bottom of the screen and smaller font size
            rc(ErrorDetails, null, orchestratorError.message || String(orchestratorError))
        ) : null,
        // User authenticated and authorized, display actual use case
        useCase ? rc(HNode, { key: useCase.hNodes.hNodeType, hNode: useCase.hNodes, currentRoute }) : null
    );
}
const UseCaseRoot = memo(_UseCaseRoot);
export default UseCaseRoot;
